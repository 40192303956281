import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

import Header from "../components/Header"
import Seo from './seo'
import Layout from '../layout/global'

import Mdx from '../components/Mdx'
import Gallery from '../components/Gallery'

import * as designSystem from '../components/DesignSystem'
import * as globalCls from './../layout/Global.module.css'
import * as cls from './Home.module.css'

const IndexPage = ({ data }) => {
	const { allMdx, site } = data
	const { siteMetadata } = site
	const { nodes } = allMdx

	// Get site metadata variables
	const { homeTitle, homeDescription } = siteMetadata

	// Populate markdown
	let hero
	let about
	let cta
	let valueProp

	nodes.map((node) => {
		const { frontmatter, body } = node
		const { page, section } = frontmatter
		if (page === "home" && section === "hero") {
			hero = body
		} else if (page === "home" && section === "about") {
			about = body
		} else if (page === "home" && section === "cta") {
			cta = body
		} else if (page === "home" && section === "valueProp") {
			valueProp = body
		}
		return null
	})

	const defaultShortcodes = designSystem.shortcodes
	const defaultShortcodesBtn = designSystem.shortcodesBtnLight

	return (
		<main>
			<Seo title={homeTitle} description={homeDescription} pageUrl="/" />
			<Header pageName={homeTitle} pageDescription={homeDescription} pageUrl="/" />
			<Layout>
				{
					// Hero section
					<section className={`${globalCls.section} ${cls.hero__height}`}>
						<StaticImage
							src="../images/home-hero-bg.jpg"
							alt="A black and white background of a modern home interior"
							placeholder="blurred"
							loading="eager"
							layout="constrained"
							objectFit="cover"
						/>
						<div className={`${cls.overlay} ${globalCls.padding}`}>
							<Mdx animate content={hero} components={defaultShortcodesBtn} />
						</div>
					</section>
				}

				{
					// About section
					<section className={`${globalCls.section} ${globalCls.padding} ${globalCls.grey__background}`}>
						<div className={`${globalCls.section__wrapper}`}>
							<Mdx content={about} components={defaultShortcodes} />
						</div>
					</section>
				}

				{
					// Value Proposition section
					<section className={`${globalCls.section} ${cls.valueProp__height}`}>
						<StaticImage
							src="../images/home-valueProp-bg.jpg"
							alt="A black and white background of a modern home"
							placeholder="blurred"
							loading="eager"
							layout="constrained"
							objectFit="cover"
							style={{ filter: 'grayscale(100%)', WebkitFilter: 'grayscale(100%)' }}
						/>
						<div className={`${cls.overlay} ${globalCls.padding}`}>
							<div className={`${globalCls.section__wrapper}`}>
								<div className={cls.content__wrapper}>
									<Mdx
										content={valueProp}
										components={defaultShortcodesBtn}
									/>
								</div>
							</div>
						</div>
					</section>
				}

				{
					// Gallery Section
					<section className={`${globalCls.section} ${globalCls.padding} ${globalCls.primary__background}`}>
						<div className={`${globalCls.section__wrapper}`}>
							<designSystem.shortcodesBtnLight.h1>Our Past Work</designSystem.shortcodesBtnLight.h1>
							<designSystem.shortcodesBtnLight.p>Check out samples of our previous work on past projects.</designSystem.shortcodesBtnLight.p>
							<Gallery display="preview" animate={true} />
							<designSystem.shortcodesBtnLight.p>
								<designSystem.shortcodesBtnLight.a href="/gallery">See Our Work</designSystem.shortcodesBtnLight.a>
							</designSystem.shortcodesBtnLight.p>
						</div>
					</section>
				}

				{
					// CTA section
					<section className={`${globalCls.section} ${cls.cta__height}`}>
						<StaticImage
							src="../images/home-cta-bg.jpg"
							alt="A black and white background of a woman staring out the window in a construction site"
							placeholder="blurred"
							loading="eager"
							layout="constrained"
							objectFit="cover"
						/>
						<div className={`${cls.overlay} ${globalCls.padding}`}>
							<div className={`${globalCls.section__wrapper}`}>
								<Mdx content={cta} components={defaultShortcodesBtn} />
							</div>
						</div>
					</section>
				}

			</Layout>
		</main >
	)
}

export default IndexPage

export const pageQuery = graphql`
  	query {
		allMdx {
			nodes {
				body
				frontmatter {
					page
					section
				}
			}
		}
		site {
			siteMetadata {
				homeTitle
				homeDescription
			}
		  }
	}
`